import React from 'react'
import Layout from '../components/layout/layout'
import SEO from '../components/seo'
import { rhythm } from '../utils/typography'
import { graphql } from 'gatsby'
import me from '../../content/assets/me.jpg'
import { css } from '@emotion/core'
import FlexContainer from '../components/layout/flex-container'

const BioIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Biography" description="Federico Gambarino's biography" />
      <div
        css={css`
          text-align: center;
        `}
      >
        <h2>Hi! I'm Federico</h2>
      </div>
      <FlexContainer
        css={css`
          align-items: start;
          flex-wrap: wrap;
          flex-flow: row;

          @media (max-width: 576px) {
            flex-flow: column;
          }
        `}
      >
        <div
          css={css`
            padding: ${rhythm(1)};
            text-align: center;
          `}
        >
          <img
            css={css`
              border-radius: 50%;
              @media (max-width: 576px) {
                max-width: 80%;
              }
            `}
            src={me}
            alt={'me'}
          />
        </div>
        <div
          css={css`
            padding: ${rhythm(1)};
            max-width: 50%;

            @media (max-width: 576px) {
              max-width: 100%;
            }
          `}
        >
          <p>
            If you are reading this, maybe you are interested in who I am. So...
            let's start!
          </p>
          <p>
            I'm an italian full stack web developer, I am really passionate
            about the job I do and about how fast and amazingly the web
            development world changes.
          </p>
          <p>
            But I am mainly the husband of a great wife, the father of the
            sweetest kids in the world and a cat lover.
          </p>
          <p>
            The idea of having a personal site is something that I think every
            developer/engineer/whatever has. My purpose is just to share
            contents, ideas and stories, hoping that they will be useful or at
            least appreciated. Moreover I took this chance for testing and
            improving my knowledge of React and Gatsby. There is always a lot to
            learn, but that's the fun!
          </p>
          <p>
            I graduated years ago in Physics - Astrophysics and cosmic rays -
            but life brought me to the path of web development, and that's
            something I didn't expect at all. I never thought in 2014, the year
            of my graduation, that I would become a web developer... I didn't
            even know HTML... But day after day, month after month and - you
            know how it goes - year after year I find in myself an ever
            increasing passion for my job. A passion that brought me to achieve
            immense satisfaction.
          </p>
          <p>
            I have many hobbies and interests: I love Science, going to the
            Cinema or watching TV Series, I play chess (I even teached the game
            for years), I played online poker as a semi-pro for more than a year
            (without going broke, but I quit anyway), I am an environmentalist
            (totally pro nuclear) and a feminist.
          </p>
          <p>
            If you want to know more about anything, just visit the other parts
            of the site!
          </p>
        </div>
      </FlexContainer>
    </Layout>
  )
}

export default BioIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
